import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { iconsObj } from '../icons/internalIcons'
import LinkButton from './buttons/LinkButton'
import ContainerCard from './ContainerCard'

const ErrorPage: React.FC = () => {
  return (
    <ContainerCard maxWidth='500px' id='error-page'>
      <Stack spacing={1}>
        <Typography variant='h4'>{iconsObj.SOMETHING_WENT_WRONG} Something went wrong</Typography>
        <Typography variant='body1'>Feel free to contact us and provide some information on the issue. Thanks!</Typography>
        <LinkButton variant='contained' to='/' fullWidth>
          Return to Dashboard
        </LinkButton>
        <LinkButton variant='contained' color='success' to='/contact-us' fullWidth sx={{ mt: 1 }}>
          {iconsObj.CONTACT_US} Contact Us
        </LinkButton>
      </Stack>
    </ContainerCard>
  )
}

export default ErrorPage
