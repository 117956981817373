import React from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'

type Props = {
  id: string
  maxWidth?: string
  children: React.ReactNode
}

const ContainerCard: React.FC<Props> = ({ id, children, maxWidth = '400px' }) => {
  const containerId = `${id}_container`
  const cardId = `${id}_card`

  return (
    <Container sx={{ minHeight: '50vh', justifyContent: 'center', alignItems: 'start', display: 'flex', marginTop: 2 }} id={containerId}>
      <Card sx={{ maxWidth, minWidth: 400, width: '100%' }} id={cardId}>
        <CardContent>{children}</CardContent>
      </Card>
    </Container>
  )
}

export default ContainerCard
