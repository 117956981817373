import React from 'react'

import jwtDecode from 'jwt-decode'
import { Redirect, Route } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'

import { User } from '../response_types/backend/User'

type RoleType = 'is_superuser' | 'is_evaluator' | 'is_controller' | 'is_submitter' | 'is_laboratory_scientist' | 'is_sysadmin' | undefined

type PrivateRouteProps = {
  path: string
  component: React.ComponentType<Record<string, never>>
  exact?: boolean
  role?: RoleType
}

type AuthRouteProps = {
  authenticated: boolean
  redirectPath: string
} & PrivateRouteProps

type ProcessTokenReturn = {
  authenticated: boolean
  redirectPath: string
}

const processToken = (token: string | null, role: RoleType): ProcessTokenReturn => {
  let authenticated = false
  let redirectPath = '/login'

  if (role) {
    if (token) {
      const tokenDecoded: User = jwtDecode(token + '/// jwt token')
      authenticated = tokenDecoded[role]
      redirectPath = ''
    }
  } else {
    authenticated = token !== null && token !== undefined && token.length > 0
  }

  return { authenticated, redirectPath }
}

const AuthRoute: React.FC<AuthRouteProps> = ({ path, component: Component, redirectPath, exact = false, authenticated = false }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(componentProps) =>
        authenticated ? (
          <Component {...componentProps.match.params} />
        ) : (
          <Redirect to={{ pathname: redirectPath, state: { from: `${componentProps.location.pathname}${componentProps.location.search}` } }} />
        )
      }
    />
  )
}

const NotAuthRoute: React.FC<AuthRouteProps> = ({ path, component: Component, redirectPath = '/', exact = false, authenticated = true }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(componentProps) => (authenticated ? <Redirect to={{ pathname: redirectPath }} /> : <Component {...componentProps.match.params} />)}
    />
  )
}

export const PublicRoute: React.FC<PrivateRouteProps> = ({ path, component: Component, exact = false, role = undefined }) => {
  const { token } = useAuth()
  const { authenticated } = processToken(token, role)

  return <NotAuthRoute authenticated={authenticated} path={path} exact={exact} component={Component} redirectPath='/' />
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, component: Component, exact = false, role = undefined }) => {
  const { token } = useAuth()
  const { authenticated, redirectPath } = processToken(token, role)

  return <AuthRoute authenticated={authenticated} path={path} exact={exact} component={Component} redirectPath={redirectPath} />
}

export default PrivateRoute
