import React, { useContext, useEffect, useState } from 'react'

import { InternalTypeOption, internalTypesOptions } from '../components/Constants'
import { useAuth } from './AuthContext'

import { ClusterStyle } from '../response_types/backend/ClusterStyle'

type ClusterContextType = {
  clusterStyle: ClusterStyle | undefined
  filteredInternalTypesOptions: InternalTypeOption[]
}

type ClusterProviderProps = {
  children: React.ReactNode
}

const ClusterContext = React.createContext<ClusterContextType>({ clusterStyle: undefined, filteredInternalTypesOptions: [] })

export const useCluster = (): ClusterContextType => useContext(ClusterContext)

export const ClusterProvider: React.FC<ClusterProviderProps> = ({ children }) => {
  const { backendRequestJson } = useAuth()

  const [clusterStyle, setClusterStyle] = useState<ClusterStyle | undefined>(undefined)

  useEffect(() => {
    const fetchStyles = async (): Promise<ClusterStyle | undefined> => {
      const stylesJson = await backendRequestJson({
        endpoint: '/cluster_styling',
        showErrorMessage: false,
      })

      return stylesJson.json as ClusterStyle | undefined
    }

    fetchStyles().then((styles) => {
      if (styles) {
        setClusterStyle(styles)
      }
    })
  }, [])

  // select only internal types that are in current cluster style and are not 'base'
  const filteredInternalTypesOptions = clusterStyle?.internalTypes
    ? internalTypesOptions.filter((option) => clusterStyle.internalTypes.includes(option.id) && option.id !== 'base')
    : []

  const value = { clusterStyle, filteredInternalTypesOptions }

  return <ClusterContext.Provider value={value}>{children}</ClusterContext.Provider>
}
