import React from 'react'

import { ButtonProps } from '@mui/material/Button'
import { TooltipProps } from '@mui/material/Tooltip'
import { Link } from 'react-router-dom'

import TooltipButton, { TooltipIconButton } from './ToooltipButton'

// This component should replace all the history.push statements in
// buttons. Using mui's "href", although working, results in weird
// page transitions with very annoying and visible page flashes

type Props = ButtonProps & {
  to: string
  children: React.ReactNode
  iconButton?: boolean
  tooltip?: string
  tooltipPlacement?: TooltipProps['placement']
  id?: string
}

const LinkButton: React.FC<Props> = (props) => {
  const ButtonComponent = props.iconButton ? TooltipIconButton : TooltipButton
  const buttonSpecificProps = props.iconButton ? {} : { fullWidth: true, startIcon: props.startIcon }

  const ButtonRender = (
    // passing all props to the button as {...props} results in the
    // button behaving like <a> component, which is not desired, as Link should
    // play the role of the <a>
    <ButtonComponent
      disabled={props.disabled}
      sx={props.sx}
      color={props.color}
      variant={props.variant}
      tooltipText={props.tooltip}
      tooltipPlacement={props.tooltipPlacement}
      {...buttonSpecificProps}
    >
      {props.children}
    </ButtonComponent>
  )
  const LinkButtonRender = (
    <Link to={props.to} id={props.id} style={{ textDecoration: 'none', color: 'inherit' }}>
      {ButtonRender}
    </Link>
  )

  return props.disabled ? ButtonRender : LinkButtonRender
}

export default LinkButton
