import React from 'react'

import Stack from '@mui/material/Stack'

import LinkButton from '../components/buttons/LinkButton'
import ContainerCard from '../components/ContainerCard'
import PageHeading from '../components/PageHeading'
import { iconsObj } from '../icons/internalIcons'

const NotFound: React.FC = () => {
  return (
    <ContainerCard id='not-found'>
      <Stack spacing={2}>
        <PageHeading icon={iconsObj.SOMETHING_WENT_WRONG}>Page Not found</PageHeading>
        <LinkButton variant='contained' to='/' fullWidth>
          Return to Dashboard
        </LinkButton>
      </Stack>
    </ContainerCard>
  )
}

export default NotFound
