import React, { lazy, Suspense } from 'react'

import Box from '@mui/material/Box'
import { Buffer } from 'buffer'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import NavBar from './components/NavBar'
import { PrivateRoute, PublicRoute } from './components/PrivateRoute'
import { AuthProvider } from './contexts/AuthContext'
import { ClusterProvider } from './contexts/ClusterContext'
import { EnumProvider } from './contexts/EnumContext'
import ErrorBoundary from './ErrorBoundary'
import NotFound from './pages/NotFound'

const AnalysesSetAnalyses = lazy(() => import('./pages/AnalysesSetAnalyses'))
const AnalysesSets = lazy(() => import('./pages/AnalysesSets'))
const ChangePassword = lazy(() => import('./pages/auth/ChangePassword'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const EndUserUpdateMessages = lazy(() => import('./pages/EndUserUpdateMessages'))
const ErrorPage = lazy(() => import('./components/ErrorPage'))
const EvaluationCovidPatient = lazy(() => import('./pages/EvaluationCovidPatient'))
const EvaluationWastewaterCovid = lazy(() => import('./pages/EvaluationWastewaterCovid'))
const EvaluationRespiratory = lazy(() => import('./pages/EvaluationRespiratory'))
const EvaluationOncology = lazy(() => import('./pages/EvaluationOncology'))
const EvaluationAntimicrobialResistance = lazy(() => import('./pages/EvaluationAntimicrobialResistance'))
const AntimicrobialResistanceAggregate = lazy(() => import('./pages/AntimicrobialResistanceAggregate'))
const Login = lazy(() => import('./pages/auth/Login'))
const PasswordRecovery = lazy(() => import('./pages/auth/PasswordRecovery'))
const Pipelines = lazy(() => import('./pages/Pipelines'))
const Plates = lazy(() => import('./pages/Plates'))
const PlateSamples = lazy(() => import('./pages/PlateSamples'))
const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'))
const Runs = lazy(() => import('./pages/Runs'))
const RunSamples = lazy(() => import('./pages/RunSamples'))
const Samples = lazy(() => import('./pages/Samples'))
const SampleBatches = lazy(() => import('./pages/SampleBatches'))
const SampleBatchSamples = lazy(() => import('./pages/SampleBatchSamples'))
const Signup = lazy(() => import('./pages/auth/Signup'))
const SubmitIssue = lazy(() => import('./pages/ContactUs'))
const TessyUpdate = lazy(() => import('./pages/TessyUpdate'))
const UserManagement = lazy(() => import('./pages/UserManagement'))
const VariantOverlap = lazy(() => import('./pages/VariantOverlap'))
const Admin = lazy(() => import('./pages/Admin'))

const App: React.FC = () => {
  // important line. Otherwise jbrowse does not download alignments correctly
  window.Buffer = window.Buffer || Buffer
  return (
    <ErrorBoundary>
      <SnackbarProvider maxSnack={3} preventDuplicate={true} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        <Router>
          <AuthProvider>
            <ClusterProvider>
              <EnumProvider>
                <Box height='100vh' display='flex' flexDirection='column'>
                  <NavBar />
                  <Switch>
                    <Suspense>
                      <PrivateRoute exact path='/' component={Dashboard} />
                      <PrivateRoute path='/signup' component={Signup} role='is_superuser' />
                      <PrivateRoute path='/users' component={UserManagement} role='is_superuser' />
                      <PrivateRoute path='/end-user-update-messages' component={EndUserUpdateMessages} role='is_superuser' />
                      <PrivateRoute path='/admin' component={Admin} role='is_superuser' />
                      <PrivateRoute path='/change-password' component={ChangePassword} />
                      <PrivateRoute exact path='/runs' component={Runs} />
                      <PrivateRoute path='/run-samples/:runId(\d+)' component={RunSamples} />
                      <PrivateRoute path='/analyses-set-analyses' component={AnalysesSetAnalyses} />
                      <PrivateRoute path='/analyses-sets' component={AnalysesSets} />
                      <PrivateRoute path='/evaluation-covid-patient' component={EvaluationCovidPatient} />
                      <PrivateRoute path='/evaluation-wastewater-covid' component={EvaluationWastewaterCovid} />
                      <PrivateRoute path='/evaluation-respiratory' component={EvaluationRespiratory} />
                      <PrivateRoute path='/evaluation-oncology' component={EvaluationOncology} />
                      <PrivateRoute path='/evaluation-antimicrobial-resistance' component={EvaluationAntimicrobialResistance} />
                      <PrivateRoute path='/evaluation-antimicrobial-resistance-aggregate' component={AntimicrobialResistanceAggregate} />
                      <PrivateRoute path='/variant_overlap' component={VariantOverlap} />
                      <PrivateRoute path='/plates' component={Plates} />
                      <PrivateRoute path='/plate-samples/:plateId(\d+)' component={PlateSamples} />
                      <PrivateRoute path='/samples' component={Samples} />
                      <PrivateRoute path='/sample-batches' component={SampleBatches} />
                      <PrivateRoute path='/sample-batch-samples/:batchId(\d+)' component={SampleBatchSamples} />
                      <PrivateRoute path='/tessy' component={TessyUpdate} role='is_sysadmin' />
                      <PrivateRoute path='/pipelines' component={Pipelines} role='is_sysadmin' />
                      <PrivateRoute path='/contact-us' component={SubmitIssue} />

                      <PublicRoute path='/login' component={Login} />
                      <PublicRoute path='/password-recovery' component={PasswordRecovery} />
                      <PublicRoute path='/reset-password' component={ResetPassword} />

                      <Route path='/something-went-wrong' component={ErrorPage} />
                      {/* This should be the last route - Default route if no matches found */}
                    </Suspense>
                    <Route path='' component={NotFound} />
                  </Switch>
                </Box>
              </EnumProvider>
            </ClusterProvider>
          </AuthProvider>
        </Router>
      </SnackbarProvider>
    </ErrorBoundary>
  )
}

export default App
