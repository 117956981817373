import React, { useContext, useEffect, useState } from 'react'

import { GridRowsProp } from '@mui/x-data-grid'

import { capitalizeFirst } from '../components/Constants'
import { Option } from '../components/forms/FormProps'
import { useAuth } from './AuthContext'

import { EnumBase, EnumLocation } from '../response_types/backend/Enums'

type EnumContextData = {
  enumOptions: Record<string, Option[]>
  enumData: Record<string, GridRowsProp>
}

type EnumContextType = EnumContextData & {
  enumLoading: boolean
  reloadEnums: () => void
}

type EnumProviderProps = {
  children: React.ReactNode
}

type EnumItem = {
  name: string
  endpoint: string
  label: string
  convertToOptions: (data?: unknown) => Option[]
  table: string
}

// convert basic enum to Option[]
const convertToOptionsId: (data?: unknown) => Option[] = (data) =>
  data ? (data as EnumBase[]).map((item) => ({ label: capitalizeFirst(item.value), id: item.id, value: item.id })) : []

const allEnums: EnumItem[] = [
  { table: 'gender', name: 'gender_id', endpoint: '/enums/gender', label: 'Sex', convertToOptions: convertToOptionsId },
  { table: 'sample_type', name: 'sample_type_id', endpoint: '/enums/sample_type', label: 'Sample type (material)', convertToOptions: convertToOptionsId },
  {
    table: 'healthcare_facility',
    name: 'healthcare_facility_id',
    endpoint: '/enums/healthcare_facility',
    label: 'Healthcare facility',
    convertToOptions: convertToOptionsId,
  },
  { table: 'patient_status', name: 'patient_status_id', endpoint: '/enums/patient_status', label: 'Patient status', convertToOptions: convertToOptionsId },
  {
    name: 'collection_location_id',
    table: 'location',
    endpoint: '/enums/location',
    label: 'Sample collection location (district)',
    convertToOptions: (data) => (data ? (data as EnumLocation[]).map((item) => ({ label: capitalizeFirst(item.district), id: item.id, value: item.id })) : []),
  },
  {
    table: 'expected_bacterial_species',
    name: 'expected_species_id',
    endpoint: '/enums/expected_bacterial_species',
    label: 'Expected bacterial species',
    convertToOptions: convertToOptionsId,
  },
  {
    table: 'host_scientific_name',
    name: 'host_scientific_name_id',
    endpoint: '/enums/host_scientific_name',
    label: 'Host scientific name',
    convertToOptions: convertToOptionsId,
  },
  { table: 'oncology_dataset', name: 'dataset_id', endpoint: '/enums/oncology_dataset', label: 'Dataset', convertToOptions: convertToOptionsId },
  {
    table: 'oncology_research_study',
    name: 'research_study_id',
    endpoint: '/enums/oncology_research_study',
    label: 'Research study',
    convertToOptions: convertToOptionsId,
  },
  {
    table: 'oncology_study_branch',
    name: 'study_branch_id',
    endpoint: '/enums/oncology_study_branch',
    label: 'Study branch',
    convertToOptions: convertToOptionsId,
  },
]

export const allEnumsMap: Record<string, EnumItem> = Object.fromEntries(allEnums.map((item) => [item.name, item]))

const EnumContext = React.createContext<EnumContextType>({ enumOptions: {}, enumData: {}, enumLoading: true, reloadEnums: () => {} })

export const useEnums = (): EnumContextType => {
  return useContext(EnumContext)
}

export const EnumProvider: React.FC<EnumProviderProps> = ({ children }) => {
  const { getParallelRequests } = useAuth()

  const [enumContextData, setEnumContextData] = useState<EnumContextData>({} as EnumContextData)
  const [enumLoading, setEnumLoading] = useState(true)
  const [reload, setReload] = useState<number>(0)

  useEffect(() => {
    const fetchAllEnums = async (): Promise<EnumContextData | undefined> => {
      // fetch all data from backend using provided function
      const responses = await getParallelRequests(allEnums.map((item) => ({ endpoint: item.endpoint })))

      // check if all fetched correctly
      const allOk = responses.every((response) => response.status === 200 && response.json !== undefined)
      if (allOk) {
        const jsons = responses.map((r) => (Array.isArray(r.json) ? r.json : []))
        // convert data from backend with convert functions
        const enumData = Object.fromEntries(allEnums.map((item, id) => [item.table, jsons[id]]))
        const enumOptions = Object.fromEntries(allEnums.map((item, id) => [item.name, item.convertToOptions(jsons[id])]))

        return { enumData, enumOptions }
      } else {
        return undefined
      }
    }

    fetchAllEnums().then((enums) => {
      if (enums) {
        setEnumContextData(enums)
      }
      setEnumLoading(false)
    })
  }, [reload])

  const value = {
    ...enumContextData,
    enumLoading,
    reloadEnums: () => {
      setReload((reload) => reload + 1)
    },
  }

  return <EnumContext.Provider value={value}>{children}</EnumContext.Provider>
}
