import React from 'react'

import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AddIcon from '@mui/icons-material/Add'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import AppsIcon from '@mui/icons-material/Apps'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import BalanceIcon from '@mui/icons-material/Balance'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import BlockIcon from '@mui/icons-material/Block'
import BlockOutlined from '@mui/icons-material/BlockOutlined'
import CachedIcon from '@mui/icons-material/Cached'
import CheckIcon from '@mui/icons-material/Check'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import CloudIcon from '@mui/icons-material/Cloud'
import Coronavirus from '@mui/icons-material/Coronavirus'
import DeleteIcon from '@mui/icons-material/Delete'
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import DownloadIcon from '@mui/icons-material/Download'
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined'
import EditIcon from '@mui/icons-material/Edit'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import EmailIcon from '@mui/icons-material/Email'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExtensionIcon from '@mui/icons-material/Extension'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import GestureIcon from '@mui/icons-material/Gesture'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LockResetIcon from '@mui/icons-material/LockReset'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import MedicationIcon from '@mui/icons-material/Medication'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import PasswordIcon from '@mui/icons-material/Password'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import SaveIcon from '@mui/icons-material/Save'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import SearchIcon from '@mui/icons-material/Search'
import SendIcon from '@mui/icons-material/Send'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import StorageIcon from '@mui/icons-material/Storage'
import TableViewIcon from '@mui/icons-material/TableView'
import TagIcon from '@mui/icons-material/Tag'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import WavesIcon from '@mui/icons-material/Waves'
/*
A guide to modifying icons in NarCoS:

1. DO NOT CHANGE THE NAME OF AN ICON!
- If you think that the name is not appropriate, please consult the change with a developer
- If you are a developer, do refactor - rename (in vscode by choosing the name and pressing F2)

2. Pick your icon at: https://mui.com/material-ui/material-icons/

3. Import the icon
For example, if you chose the icon EditIcon, include it in the import statement in the top of the file:
import EditIcon from '@mui/icons-material/Edit'

4. Change icon in the iconsObj object below:
ORIGINAL -> MODIFY: <FaTable fontSize='inherit' />
NEW      -> MODIFY: <EditIcon fontSize='inherit' />
*/

export const iconsObj = {
  MODIFY: <EditIcon fontSize='inherit' />,
  SAVE: <SaveIcon fontSize='inherit' />,
  ADD: <AddIcon fontSize='inherit' />,
  DELETE: <DeleteIcon fontSize='inherit' />,
  DOWNLOAD: <DownloadIcon fontSize='inherit' />,
  UPLOAD: <FileUploadIcon fontSize='inherit' />,
  RELOAD: <CachedIcon fontSize='inherit' />,
  FILE_UPLOAD: <AttachFileIcon fontSize='inherit' />,
  CLOSE: <CloseIcon fontSize='inherit' />,
  SEND: <SendIcon fontSize='inherit' />,
  SEND_MAIL: <EmailIcon fontSize='inherit' />,
  CORRECT: <CheckIcon fontSize='inherit' />,
  INCORRECT: <ClearIcon fontSize='inherit' />,
  QUESTIONABLE: <QuestionMarkIcon fontSize='inherit' />,
  SUBMIT: <CheckCircleOutlineIcon fontSize='inherit' />,
  REJECT: <HighlightOffIcon fontSize='inherit' />,
  UPDATE_PLATE: <CachedIcon fontSize='inherit' />,
  PLATE_ARRIVED: <LocalShippingIcon fontSize='inherit' />,
  PLATE_VIEW: <TableViewIcon fontSize='inherit' />,
  RUN_ANALYSIS: <SearchIcon fontSize='inherit' />,
  PLATE_VIEW_FORBIDDEN: <BlockOutlined fontSize='inherit' />,
  PLATE_VIEW_CHECKBOX: <CheckBoxOutlineBlankOutlined fontSize='inherit' />,
  NEXTCLADE_MD5: <TagIcon fontSize='inherit' />,
  VARIANT_OVERLAP: <BalanceIcon fontSize='inherit' />,
  DOWNLOAD_MISSING_SAMPLES: <SentimentVeryDissatisfiedIcon fontSize='inherit' />,
  DOWNLOAD_COMPLETE_SAMPLES: <SentimentSatisfiedAltIcon fontSize='inherit' />,
  RESET_EVALUATION: <PriorityHighIcon fontSize='inherit' />,
  PLATES: <TableViewIcon fontSize='inherit' />,
  RUNS: <DirectionsRunIcon fontSize='inherit' />,
  SAMPLES: <ScienceOutlinedIcon fontSize='inherit' />,
  DATA: <StorageIcon fontSize='inherit' />,
  SIGNUP: <PersonAddIcon fontSize='inherit' />,
  USER_MANAGEMENT: <ManageAccountsIcon fontSize='inherit' />,
  PASSWORD: <PasswordIcon fontSize='inherit' />,
  LOGOUT: <LogoutIcon fontSize='inherit' />,
  LOGIN: <LoginIcon fontSize='inherit' />,
  PASSWORD_RECOVERY: <LockResetIcon fontSize='inherit' />,
  CONTACT_US: <SendIcon fontSize='inherit' />,
  SOMETHING_WENT_WRONG: <SentimentDissatisfiedIcon fontSize='inherit' />,
  ANALYSES_SETS: <BarChartOutlinedIcon fontSize='inherit' />,
  WASTEWATER: <WavesIcon fontSize='inherit' />,
  RIGHT_ARROW: <ArrowRightAltIcon fontSize='inherit' />,
  CHECKBOX: <CheckBoxOutlineBlankIcon fontSize='small' />,
  CHECKBOX_CHECKED: <CheckBoxIcon fontSize='small' />,
  VIRUS: <Coronavirus />,
  BIG_EMPTY: <BlockIcon fontSize='large' />,
  EXPAND_MORE: <ExpandMoreIcon />,
  ONCOLOGY: <CloudIcon />,
  PIPELINES: <AccountTreeIcon />,
  NEXT: <ChevronRightIcon />,
  PREVIOUS: <ChevronLeftIcon />,
  CHANGE_WELL: <AppRegistrationIcon fontSize='inherit' />,
  LIBRARY_PREP: <LibraryBooksIcon fontSize='inherit' />,
  SEQUENCING_KIT: <DeveloperBoardIcon fontSize='inherit' />,
  PLATFORM: <ExtensionIcon fontSize='inherit' />,
  APP: <AppsIcon fontSize='inherit' />,
  INDEX_ADAPTER: <ElectricalServicesIcon fontSize='inherit' />,
  SAMPLE_BATCHES: <DynamicFeedOutlinedIcon fontSize='inherit' />,
  MEDICATION: <MedicationIcon fontSize='inherit' />,
  SNIPPET: <TextSnippetIcon fontSize='inherit' />,
  AMR_BANDAGE: <GestureIcon fontSize='inherit' />,
  MONITOR: <MonitorHeartIcon fontSize='inherit' />,
  ADMIN: <AdminPanelSettingsIcon />,
}
