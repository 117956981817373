import React from 'react'

import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'

import { iconsObj } from './internalIcons'
import pangolin from './pangolin.svg'

export const PangolinIcon: React.FC = () => {
  return (
    <Icon>
      <img src={pangolin} alt='Pangolin' style={{ marginBottom: '13px' }} />
    </Icon>
  )
}

const PangoToTessyLogo: React.FC = () => {
  return (
    <Stack direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
      Pango {iconsObj.RIGHT_ARROW} Tessy
    </Stack>
  )
}

export default PangoToTessyLogo
