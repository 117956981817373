import React from 'react'

import { Option } from './forms/FormProps'

// https://mui.com/material-ui/customization/palette/#default-values
export const colors = {
  primary: '#42a5f5',
  secondary: '#ab47bc',
  success: '#388e3c',
  info: '#0288d1',
  warning: '#f57c00',
  error: '#d32f2f',
  textSecondary: 'rgba(0, 0, 0, 0.6)',
  PASS: 'rgb(104, 184, 68)',
  FAIL: 'rgb(218, 78, 60)',
  NEED_DECISION: 'rgb(228, 144, 47)',
}

const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return { r, g, b }
}

const rgbToHex = (r: number, g: number, b: number): string => `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`

const interpolateColor = (colors: string[], scale: number): string => {
  if (colors.length < 2) {
    throw new Error('At least two colors are required')
  }
  if (scale < 0.0 || scale > 1.0) {
    throw new Error('Scale should be within <0.0, 1.0>')
  }

  const colorDistRatio = 1.0 / (colors.length - 1)
  const firstColor = Math.floor(scale / colorDistRatio)
  const remaining = scale / colorDistRatio - firstColor
  if (remaining === 0.0) return colors[firstColor]

  const color1 = hexToRgb(colors[firstColor])
  const color2 = hexToRgb(colors[firstColor + 1])

  const r = Math.round(color1.r + (color2.r - color1.r) * remaining)
  const g = Math.round(color1.g + (color2.g - color1.g) * remaining)
  const b = Math.round(color1.b + (color2.b - color1.b) * remaining)

  return rgbToHex(r, g, b)
}

const colorPalette = ['#ab47bc', '#0288d1', '#388e3c'] // purple to blue to green similar to Runs

const analysesSetsStatusColors = {
  CREATED: interpolateColor(colorPalette, 0 / 6),
  ANALYSING: interpolateColor(colorPalette, 1 / 6),
  EVALUATING: interpolateColor(colorPalette, 2 / 6),
  CONTROLLING: interpolateColor(colorPalette, 3 / 6),
  EVALUATION_ACCEPTED: interpolateColor(colorPalette, 4 / 6),
  SUBMITTING: interpolateColor(colorPalette, 5 / 6),
  COMPLETED: interpolateColor(colorPalette, 6 / 6),
  FAILED: colors.error,
  TECHNICAL_FAIL: 'brown',
}

export const statusObj = {
  ...analysesSetsStatusColors,
  NOT_STARTED: colors.secondary,
  WAITING: colors.secondary,
  IN_LABORATORY: colors.warning,
  ASSIGNED_IN_RUN: colors.warning,
  IMPORTING_READS: colors.info,
  READS_READY: colors.info,
  RUNNING_ANALYSIS: colors.primary,
  RUNNING: colors.primary,
  IN_PROGRESS: colors.primary,
  ANALYSED: colors.success,
  REPORTED: colors.success,
  SUCCESS: colors.success,
  ANALYSIS_FAILED: colors.error,
  FAILED: colors.error,
  READY_FOR_EVALUATION: 'darkgreen',
  SENT: analysesSetsStatusColors.ANALYSING,
  RECEIVED: analysesSetsStatusColors.EVALUATION_ACCEPTED,
  VERIFIED: analysesSetsStatusColors.COMPLETED,
  TECHNICAL_FAIL: analysesSetsStatusColors.TECHNICAL_FAIL,
}

export const cycles: Option[] = [
  { value: 36, id: 0, label: '2 x 36' },
  { value: 74, id: 1, label: '2 x 74' },
  { value: 75, id: 2, label: '2 x 75' },
  { value: 90, id: 3, label: '2 x 90' },
  { value: 100, id: 4, label: '2 x 100' },
  { value: 150, id: 5, label: '2 x 150' },
  { value: 151, id: 6, label: '2 x 151' },
  { value: 300, id: 7, label: '2 x 300' },
]

export const columnWidths = {
  id: 20,
}

export const facilitiesColors = {
  vpuk: '#1abc9c',
  ruvztn: '#2ecc71',
  ruvzke: '#3498db',
  ruvzbb: '#9b59b6',
  bmcsav: '#f1c40f',
  uvzsr: '#f39c12',
  jlfuk: '#e74c3c',
}

export type FacilitiesColors = typeof facilitiesColors

export const createErrorMessage = (message: string | { msg: string }[] | Record<string, unknown>): string => {
  if (typeof message === 'string') {
    return message
  } else if (Array.isArray(message) && Object.prototype.hasOwnProperty.call(message[0], 'msg')) {
    return message[0].msg
  } else {
    return JSON.stringify(message)
  }
}

export const handleMessageVisibility = (setMessage: React.Dispatch<React.SetStateAction<string>>, message: string, seconds: number = 2): void => {
  setMessage(message)
  setTimeout(() => {
    setMessage('')
  }, seconds * 1000)
}

export const genomeFilterOptions: Option[] = [
  { id: 0, value: 'ALL', label: 'Entire Genome' },
  { id: 1, value: 'S', label: 'Gene S' },
  { id: 2, value: 'E', label: 'Gene E' },
  { id: 3, value: 'M', label: 'Gene M' },
  { id: 4, value: 'N', label: 'Gene N' },
  { id: 5, value: 'ORF1a', label: 'Gene ORF1a' },
  { id: 6, value: 'ORF1b', label: 'Gene ORF1b' },
  { id: 7, value: 'ORF3a', label: 'Gene ORF3a' },
  { id: 8, value: 'ORF6', label: 'Gene ORF6' },
  { id: 9, value: 'ORF7a', label: 'Gene ORF7a' },
  { id: 10, value: 'ORF7b', label: 'Gene ORF7b' },
  { id: 11, value: 'ORF8', label: 'Gene ORF8' },
  { id: 12, value: 'ORF9b', label: 'Gene ORF9b' },
]

export const genomeViewContent = {
  NUCLEOTIDES: 'NUCLEOTIDES',
  AMINO_ACIDS: 'AMINO_ACIDS',
}

export const sequencerColors = {
  'NextSeq 500': colors.primary,
  'NextSeq 550': colors.info,
  'Illumina MiSeq': '#FF00FF',
  'Illumina MiniSeq': colors.error,
  'NextSeq 2000': '#5C946E',
}

export type SequencerColors = typeof sequencerColors

// https://gist.github.com/mlocati/7210513
export const perc2color = (perc: number): string => {
  let r = 0
  let g = 0
  const b = 0
  if (perc < 50) {
    r = 255
    g = Math.round(5.1 * perc)
  } else {
    g = 255
    r = Math.round(510 - 5.1 * perc)
  }
  const h = r * 0x10000 + g * 0x100 + b * 0x1
  return '#' + ('000000' + h.toString(16)).slice(-6)
}

export type InternalType = 'base' | 'covid_patient' | 'wastewater_covid' | 'respiratory' | 'oncology' | 'antimicrobial_resistance'
export type InternalTypeNoBase = Exclude<InternalType, 'base'>
export type InternalTypeEndpoint =
  | 'samples'
  | 'samples_covid_patient'
  | 'samples_wastewater_covid'
  | 'samples_respiratory'
  | 'samples_oncology'
  | 'samples_antimicrobial_resistance'
export type InternalTypeOption = { id: InternalType; value: InternalTypeEndpoint; label: string }
export const internalTypesObj: Record<InternalType, InternalTypeOption> = {
  base: { id: 'base', value: 'samples', label: 'All' },
  covid_patient: { id: 'covid_patient', value: 'samples_covid_patient', label: 'Covid Patient' },
  wastewater_covid: { id: 'wastewater_covid', value: 'samples_wastewater_covid', label: 'Wastewater Covid' },
  respiratory: { id: 'respiratory', value: 'samples_respiratory', label: 'Respiratory' },
  oncology: { id: 'oncology', value: 'samples_oncology', label: 'Oncology' },
  antimicrobial_resistance: { id: 'antimicrobial_resistance', value: 'samples_antimicrobial_resistance', label: 'Antimicrobial Resistance' },
}
export const internalTypesOptions = Object.values(internalTypesObj)
export const internalTypesOptionsNoBase: InternalTypeOption[] = internalTypesOptions.filter((i) => i.value !== 'samples')

export const diffColors = {
  ADD: 'rgba(25, 135, 84, .2)', // aka success
  DELETE: 'rgba(220, 53, 69, .2)', // aka danger
  UPDATE: 'rgba(255, 193, 7, .2)', // aka warning
  UNCHANGED: 'white',
}

export type RunStatusType = 'CREATED' | 'ANALYSING' | 'EVALUATING' | 'COMPLETED'
export const runStatusObj: Record<RunStatusType, { name: RunStatusType; color: string; value: number }> = {
  CREATED: { name: 'CREATED', color: '#6c5ce7', value: 25 },
  ANALYSING: { name: 'ANALYSING', color: '#0984e3', value: 50 },
  EVALUATING: { name: 'EVALUATING', color: '#00cec9', value: 75 },
  COMPLETED: { name: 'COMPLETED', color: '#00b894', value: 100 },
}

export type RunStatusObj = typeof runStatusObj

export const removeNullValues = <T>(input: Record<string, T | null | undefined>) => {
  return Object.fromEntries(Object.entries(input).filter((entry): entry is [string, T] => entry[1] !== null && entry[1] !== undefined))
}

export const camelToSnakeCase = (str: string): string => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
export const snakeToCamelCase = (str: string): string => str.replace(/(\s|\t|_)([a-z])/g, (g) => g[1].toUpperCase())
export const capitalizeFirst = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)
export const capitalizeAllFirst = (str: string): string =>
  str
    .split(' ')
    .map((word) => capitalizeFirst(word))
    .join(' ')
export const range = (end: number): number[] => Array.from({ length: end }, (_, i) => i)
