import React, { Component, ErrorInfo, ReactNode } from 'react'

import ErrorPage from './components/ErrorPage'

// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/#options-2-writing-your-custom-error-boundary-component

type Props = {
  children?: ReactNode
}

type State = {
  hasError: boolean
}

/* eslint-disable space-before-function-paren */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo)
  }

  render(): React.ReactNode {
    /* eslint-enable space-before-function-paren */
    if (this.state.hasError) {
      return <ErrorPage />
    }
    return this.props.children
  }
}

export default ErrorBoundary
