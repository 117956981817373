import React, { useContext, useEffect, useState } from 'react'

import saveAs from 'file-saver'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import urljoin from 'url-join'

import { createErrorMessage } from '../components/Constants'
import getCurrentTime from '../components/scripts/getCurrentTime'

import { Login } from '../response_types/backend/Login'
import { User } from '../response_types/backend/User'
import { ErrorResponse } from '../response_types/common/Error'

export type BackendRequestProps = {
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT'
  endpoint: string
  body?: FormData | Record<string, unknown> | Array<unknown>
  requiresAuth?: boolean
  headers?: Headers
  apiVersion?: string
  errorMessageDuration?: number
  showErrorMessage?: boolean
}

type BackendRequestFileProps = BackendRequestProps & { filename: string }

type StatusJson = {
  status: number
  statusText: string
  json?: unknown
}

type AuthContextType = {
  token: string | null
  currentUser: User | null
  backendRequestJson: (props: BackendRequestProps) => Promise<StatusJson>
  backendRequestFile: (props: BackendRequestFileProps) => Promise<boolean>
  backendRequestBase: (props: BackendRequestProps) => Promise<Response>
  backendErrorMessage: (props: BackendRequestProps) => Promise<string>
  login: (email: string, password: string) => Promise<string>
  logout: () => void
  passwordRecovery: (email: string) => Promise<string>
  passwordReset: (token: string, newPassword: string) => Promise<string>
  updateUserProfile: (objIn: Record<string, unknown>) => Promise<string>
  updateUserProfileAdmin: (objIn: Record<string, unknown>, userId: number) => Promise<string>
  getParallelRequests: (requests: BackendRequestProps[]) => Promise<StatusJson[]>
  namespace: 'dev' | 'staging' | 'prod'
  refetchUser: () => void
}

export const AuthContext = React.createContext<AuthContextType | null>(null)

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext) as AuthContextType
}

type AuthProviderProps = {
  children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('userToken'))
  const [loading, setLoading] = useState<boolean>(true)

  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [refetchCurrentUser, setRefetchCurrentUser] = useState<number>(0)

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const backendRequestJson: (props: BackendRequestProps) => Promise<StatusJson> = async (props) => {
    const response = await backendRequestBase(props)
    return { status: response.status, statusText: response.statusText, json: response.ok ? await response.json().catch(() => undefined) : undefined }
  }

  const backendRequestFile: (props: BackendRequestFileProps) => Promise<boolean> = async ({ filename, ...props }) => {
    const response = await backendRequestBase(props)
    if (response.ok) {
      return await response
        .blob()
        .then((blob) => {
          saveAs(blob, filename)
          return true
        })
        .catch(() => false)
    } else {
      return false
    }
  }

  const backendRequestBase: (props: BackendRequestProps) => Promise<Response> = async ({
    method = 'GET',
    endpoint,
    body,
    requiresAuth = true,
    headers,
    apiVersion = process.env.REACT_APP_BACKEND_VERSION ?? 'undef',
    errorMessageDuration = 5000,
    showErrorMessage = true,
  }) => {
    const controller = new AbortController()
    const timeoutID = setTimeout(
      () => {
        console.error(`Request ${method} ${endpoint} took too long. Aborting Request`)
        controller.abort()
        history.push('/something-went-wrong')
      },
      60 * 3 * 1000
    ) // ie 3 minutes
    // process body
    let requestBody = null
    if (body) {
      if (body instanceof FormData) {
        requestBody = body
      } else if (body !== undefined) {
        requestBody = JSON.stringify(body)
      }
    }

    // collect headers
    const requestHeaders = new Headers(headers ?? { 'Content-Type': 'application/json' })
    if (requiresAuth === true) {
      requestHeaders.set('Authorization', 'Bearer ' + token)
    }
    requestHeaders.set('timestamp', getCurrentTime())

    // fetch
    const backendEndpoint = urljoin(apiVersion, endpoint)
    const response = await fetch(backendEndpoint, { method, headers: requestHeaders, signal: controller.signal, body: requestBody })

    // set error message
    if (response.status !== 200) {
      if (response.status === 401) {
        logout()
      }
      const errorMessage = await response
        .clone()
        .json()
        .then((data) => createErrorMessage(data.detail))
        .catch(() => response.statusText)
      showErrorMessage && enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: errorMessageDuration })
    }

    // If server error, redirect to Something Went Wrong page
    if (response.status >= 500) {
      history.push('/something-went-wrong')
    }

    clearTimeout(timeoutID) // important, otherwise the timeout is not reset and continues => abort request
    return response
  }

  const backendErrorMessage: (props: BackendRequestProps) => Promise<string> = async ({
    method,
    endpoint,
    body,
    requiresAuth = true,
    apiVersion,
    headers,
    showErrorMessage = false,
  }) => {
    const response = await backendRequestBase({ method, endpoint, body, requiresAuth, apiVersion, headers, showErrorMessage })

    if (response.status !== 200) {
      return await response
        .json()
        .then((data) => createErrorMessage(data.detail))
        .catch(() => response.statusText)
    }
    return ''
  }

  const login: (email: string, password: string) => Promise<string> = async (email, password) => {
    const requestData = new FormData()
    requestData.append('username', email)
    requestData.append('password', password)

    const headers = new Headers()
    const statusJson = await backendRequestJson({
      method: 'POST',
      endpoint: '/login/access-token',
      body: requestData,
      requiresAuth: false,
      headers,
    })

    if (statusJson.status !== 200 && statusJson.json && Object.prototype.hasOwnProperty.call(statusJson.json, 'detail')) {
      const errorResponse = statusJson.json as ErrorResponse
      if (errorResponse.detail instanceof Array) {
        return errorResponse.detail.map((x: { loc: string[]; msg: string }) => x.loc[1] + ' ' + x.msg).join(' ')
      } else {
        return errorResponse.detail as string
      }
    } else {
      setToken((statusJson.json as Login | undefined)?.access_token ?? null)
    }

    return ''
  }

  const logout = () => {
    localStorage.removeItem('userToken')
    setToken(null)
  }

  const passwordRecovery = async (email: string) =>
    await backendErrorMessage({ method: 'POST', endpoint: `/password-recovery/${email}`, body: { email }, requiresAuth: false })

  const passwordReset = async (token: string, newPassword: string) =>
    await backendErrorMessage({ method: 'POST', endpoint: '/reset-password', body: { token, new_password: newPassword }, requiresAuth: false })

  const updateUserProfile = async (objIn: Record<string, unknown>) => await backendErrorMessage({ method: 'PUT', endpoint: '/users/me', body: objIn })

  const updateUserProfileAdmin = async (objIn: Record<string, unknown>, userId: number) =>
    await backendErrorMessage({ method: 'PUT', endpoint: `/users/${userId}`, body: Object.assign(objIn, { password: '' }) })

  const getParallelRequests = async (requests: BackendRequestProps[]) => {
    // build promises for JSONs
    const promises = requests.map(async (request) => await backendRequestJson(request))
    // resolve all promises
    return Promise.all(promises)
  }

  const refetchUser = () => {
    setRefetchCurrentUser((refetchCurrentUser) => refetchCurrentUser + 1)
  }

  // use the token that is currently saved in local storage and
  // send a request to '/users/me' endpoint
  // if the response is successfull, then the token is valid and user
  // can remain logged in. Otherwise log out <=> set local storage to Null
  useEffect(() => {
    const fetchUser = async () => {
      const statusJson = await backendRequestJson({
        endpoint: '/users/me',
        showErrorMessage: false,
      })
      if (statusJson.status !== 200) {
        setToken(null)
      }
      if (token) {
        localStorage.setItem('userToken', token)
      }
      return statusJson.json as User | undefined
    }
    fetchUser().then((data) => {
      setCurrentUser(data ?? null)
      setLoading(false)
    })
  }, [token, refetchCurrentUser])

  const currentSite = window.location.origin
  const namespace = currentSite.includes('dev') ? 'dev' : currentSite.includes('staging') ? 'staging' : 'prod'

  const value: AuthContextType = {
    token,
    currentUser,
    backendRequestBase,
    backendErrorMessage,
    backendRequestJson,
    backendRequestFile,
    login,
    logout,
    passwordRecovery,
    passwordReset,
    updateUserProfile,
    updateUserProfileAdmin,
    getParallelRequests,
    namespace,
    refetchUser,
  }

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}
