import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useCluster } from '../contexts/ClusterContext'

const Logo: React.FC = () => {
  const fontStyle = { fontFamily: 'Stardos Stencil', fontSize: 22 }
  const { clusterStyle } = useCluster()
  return (
    <Stack display='flex' alignItems='center' spacing={1} direction='row'>
      <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox={clusterStyle?.logo.viewBox} fill='#FFFFFF'>
        <path d={clusterStyle?.logo.d} />
      </svg>
      <Typography sx={fontStyle}>{clusterStyle?.logo.text}</Typography>
    </Stack>
  )
}
export default Logo
